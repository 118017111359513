//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import vue from 'kolibri.lib.vue';
import videojs from 'video.js';
import throttle from 'lodash/throttle';
import Lockr from 'lockr';
import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import KCircularLoader from 'kolibri.coreVue.components.KCircularLoader';
import ResponsiveElement from 'kolibri.coreVue.mixins.responsiveElement';
import contentRendererMixin from 'kolibri.coreVue.mixins.contentRendererMixin';
import CoreFullscreen from 'kolibri.coreVue.components.CoreFullscreen';
import { fullscreenApiIsSupported } from 'kolibri.utils.browser';
import { ReplayButton, ForwardButton, MimicFullscreenToggle } from './customButtons';
import audioIconPoster from './audio-icon-poster.svg';

const GlobalLangCode = vue.locale;

const MEDIA_PLAYER_SETTINGS_KEY = 'kolibriMediaPlayerSettings';

export default {
  name: 'MediaPlayerIndex',
  $trs: {
    replay: 'Go back 10 seconds',
    // Pulled from https://github.com/videojs/video.js/blob/master/lang/en.json
    forward: 'Go forward 10 seconds',
    play: 'Play',
    pause: 'Pause',
    currentTime: 'Current time',
    durationTime: 'Duration time',
    loaded: 'Loaded',
    progress: 'Progress',
    progressBar: 'Progress bar',
    fullscreen: 'Fullscreen',
    nonFullscreen: 'Non-fullscreen',
    mute: 'Mute',
    unmute: 'Unmute',
    playbackRate: 'Playback rate',
    captions: 'Captions',
    captionsOff: 'Captions off',
    volumeLevel: 'Volume level',
    networkError: 'A network error caused the media download to fail part-way',
    formatError:
      'The media could not be loaded, either because the server or network failed or because the format is not supported',
    corruptionOrSupportError:
      'The media playback was aborted due to a corruption problem or because the media used features your browser did not support',
    sourceError: 'No compatible source was found for this media',
    encryptionError: 'The media is encrypted and we do not have the keys to decrypt it',
  },
  components: { KCircularLoader, CoreFullscreen },

  mixins: [ResponsiveElement, contentRendererMixin, themeMixin],

  data: () => ({
    dummyTime: 0,
    progressStartingPoint: 0,
    lastUpdateTime: 0,
    loading: true,
    playerVolume: 1.0,
    playerMuted: false,
    playerRate: 1.0,
    videoLangCode: GlobalLangCode,
    updateContentStateInterval: null,
  }),

  computed: {
    posterSources() {
      const posterFileExtensions = ['png', 'jpg'];
      return this.thumbnailFiles.filter(file =>
        posterFileExtensions.some(ext => ext === file.extension)
      );
    },
    audioPoster() {
      if (this.posterSources.length) {
        return this.posterSources[0].storage_url;
      }
      return audioIconPoster;
    },
    videoSources() {
      const videoFileExtensions = ['mp4', 'webm', 'ogg'];
      return this.files.filter(file => videoFileExtensions.some(ext => ext === file.extension));
    },
    audioSources() {
      const audioFileExtensions = ['mp3'];
      return this.files.filter(file => audioFileExtensions.some(ext => ext === file.extension));
    },
    trackSources() {
      const trackFileExtensions = ['vtt'];
      return this.supplementaryFiles.filter(file =>
        trackFileExtensions.some(ext => ext === file.extension)
      );
    },
    isVideo() {
      return this.videoSources.length;
    },
    savedLocation() {
      if (this.extraFields && this.extraFields.contentState) {
        return this.extraFields.contentState.savedLocation;
      }
      return 0;
    },
    progressStyle() {
      return {
        '.vjs-play-progress': {
          backgroundColor: this.$coreActionNormal,
          '::before': {
            color: this.$coreActionNormal,
          },
        },
      };
    },
  },
  created() {
    ReplayButton.prototype.controlText_ = this.$tr('replay');
    ForwardButton.prototype.controlText_ = this.$tr('forward');
    videojs.registerComponent('ReplayButton', ReplayButton);
    videojs.registerComponent('ForwardButton', ForwardButton);
    const { videoLangCode = this.videoLangCode } = this.getSavedSettings();
    this.videoLangCode = videoLangCode;
  },
  mounted() {
    this.initPlayer();
    window.addEventListener('resize', this.throttledResizePlayer);
  },
  beforeDestroy() {
    this.updateContentState();
    this.$emit('stopTracking');
    window.removeEventListener('resize', this.throttledResizePlayer);
    this.player.dispose();
    clearInterval(this.updateContentStateInterval);
  },
  methods: {
    isDefaultTrack(langCode) {
      const shortLangCode = langCode.split('-')[0];
      const shortGlobalLangCode = this.videoLangCode.split('-')[0];
      if (shortLangCode === shortGlobalLangCode) {
        return true;
      }
      return false;
    },
    initPlayer() {
      const videojsConfig = {
        fluid: true,
        aspectRatio: '16:9',
        controls: true,
        textTrackDisplay: true,
        bigPlayButton: true,
        preload: 'metadata',
        playbackRates: [0.5, 1.0, 1.25, 1.5, 2.0],
        controlBar: {
          children: [
            { name: 'playToggle' },
            { name: 'ReplayButton' },
            { name: 'ForwardButton' },
            { name: 'currentTimeDisplay' },
            { name: 'progressControl' },
            { name: 'timeDivider' },
            { name: 'durationDisplay' },
            {
              name: 'volumePanel',
              inline: false,
            },
            { name: 'playbackRateMenuButton' },
            { name: 'captionsButton' },
          ],
        },
        language: GlobalLangCode,
        languages: {
          [GlobalLangCode]: {
            Play: this.$tr('play'),
            Pause: this.$tr('pause'),
            'Current Time': this.$tr('currentTime'),
            'Duration Time': this.$tr('durationTime'),
            Loaded: this.$tr('loaded'),
            Progress: this.$tr('progress'),
            'Progress Bar': this.$tr('progressBar'),
            Fullscreen: this.$tr('fullscreen'),
            'Non-Fullscreen': this.$tr('nonFullscreen'),
            Mute: this.$tr('mute'),
            Unmute: this.$tr('unmute'),
            'Playback Rate': this.$tr('playbackRate'),
            Captions: this.$tr('captions'),
            'captions off': this.$tr('captionsOff'),
            'Volume Level': this.$tr('volumeLevel'),
            'A network error caused the media download to fail part-way.': this.$tr(
              'networkError'
            ),
            'The media could not be loaded, either because the server or network failed or because the format is not supported.': this.$tr(
              'formatError'
            ),
            'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': this.$tr(
              'corruptionOrSupportError'
            ),
            'No compatible source was found for this media.': this.$tr('sourceError'),
            'The media is encrypted and we do not have the keys to decrypt it.': this.$tr(
              'encryptionError'
            ),
          },
        },
      };

      if (!this.isVideo) {
        videojsConfig.poster = this.audioPoster;
      }

      // Add appropriate fullscreen button
      if (fullscreenApiIsSupported) {
        videojsConfig.controlBar.children.push({ name: 'fullscreenToggle' });
      } else {
        videojs.registerComponent('MimicFullscreenToggle', MimicFullscreenToggle);
        videojsConfig.controlBar.children.push({ name: 'MimicFullscreenToggle' });
      }

      this.$nextTick(() => {
        this.player = videojs(this.$refs.player, videojsConfig, this.handleReadyPlayer);
      });
    },
    handleReadyPlayer() {
      const startTime = this.savedLocation >= this.player.duration() ? 0 : this.savedLocation;
      this.player.currentTime(startTime);
      this.player.play();

      this.player.on('play', () => {
        this.focusOnPlayControl();
        this.setPlayState(true);
      });
      this.player.on('pause', () => {
        this.focusOnPlayControl();
        this.setPlayState(false);
        this.updateContentState();
      });
      this.player.on('timeupdate', this.updateTime);
      this.player.on('seeking', this.handleSeek);
      this.player.on('volumechange', this.throttledUpdateVolume);
      this.player.on('ratechange', this.updateRate);
      this.player.on('texttrackchange', this.updateLang);
      this.player.on('ended', () => this.setPlayState(false));
      this.player.on('mimicFullscreenToggled', () => {
        this.$refs.container.toggleFullscreen();
      });
      this.$watch('elementWidth', this.updatePlayerSizeClass);
      this.updatePlayerSizeClass();
      this.resizePlayer();
      this.useSavedSettings();
      this.loading = false;
      this.$refs.player.tabIndex = -1;

      this.updateContentStateInterval = setInterval(this.updateContentState, 30000);
    },
    resizePlayer() {
      const wrapperWidth = this.$refs.wrapper.clientWidth;
      const aspectRatio = 16 / 9;
      const adjustedHeight = wrapperWidth * (1 / aspectRatio);
      this.$refs.wrapper.setAttribute('style', `height:${adjustedHeight}px`);
    },
    throttledResizePlayer: throttle(function resizePlayer() {
      this.resizePlayer();
    }, 300),

    throttledUpdateVolume: throttle(function updateVolume() {
      this.updateVolume();
    }, 1000),

    getSavedSettings() {
      return Lockr.get(MEDIA_PLAYER_SETTINGS_KEY) || {};
    },
    saveSettings(updatedSettings) {
      const savedSettings = this.getSavedSettings();
      Lockr.set(MEDIA_PLAYER_SETTINGS_KEY, {
        ...savedSettings,
        ...updatedSettings,
      });
    },
    updateVolume() {
      this.saveSettings({
        playerVolume: this.player.volume(),
        playerMuted: this.player.muted(),
      });
    },

    updateRate() {
      this.saveSettings({
        playerRate: this.player.playbackRate(),
      });
    },

    updateLang() {
      const currentTrack = Array.from(this.player.textTracks()).find(
        track => track.mode === 'showing'
      );
      if (currentTrack) {
        this.saveSettings({
          videoLangCode: currentTrack.language,
        });
      }
    },

    useSavedSettings() {
      const {
        savedPlayerVolume = this.playerVolume,
        savedPlayerMuted = this.playerMuted,
        savedPlayerRate = this.playerRate,
      } = this.getSavedSettings();
      this.playerVolume = savedPlayerVolume;
      this.playerMuted = savedPlayerMuted;
      this.playerRate = savedPlayerRate;
      this.player.volume(this.playerVolume);
      this.player.muted(this.playerMuted);
      this.player.playbackRate(this.playerRate);
    },

    focusOnPlayControl() {
      const wrapper = this.$refs.wrapper;
      wrapper.getElementsByClassName('vjs-play-control')[0].focus();
    },
    handleSeek() {
      // record progress before updating the times,
      // to capture any progress that happened pre-seeking
      this.recordProgress();

      // now, update all the timestamps to set the new time location
      // as the baseline starting point
      this.dummyTime = this.player.currentTime();
      this.lastUpdateTime = this.dummyTime;
      this.progressStartingPoint = this.dummyTime;
    },
    updateTime() {
      // skip out of here if we're currently seeking,
      // so we don't update this.dummyTime before calculating old progress
      if (this.player.seeking()) {
        return;
      }
      this.dummyTime = this.player.currentTime();
      if (this.dummyTime - this.lastUpdateTime >= 5) {
        this.recordProgress();
        this.lastUpdateTime = this.dummyTime;
      }
    },
    setPlayState(state) {
      // avoid recording progress if we're currently seeking,
      // as timers are in an intermediate state
      if (!this.player.seeking()) {
        this.recordProgress();
      }
      if (state === true) {
        this.$emit('startTracking');
      } else {
        this.$emit('stopTracking');
      }
    },
    recordProgress() {
      this.$emit(
        'updateProgress',
        Math.max(
          0,
          (this.dummyTime - this.progressStartingPoint) / Math.floor(this.player.duration())
        )
      );
      this.progressStartingPoint = this.player.currentTime();
    },
    updatePlayerSizeClass() {
      this.player.removeClass('player-medium');
      this.player.removeClass('player-small');
      this.player.removeClass('player-tiny');

      if (this.elementWidth < 600) {
        this.player.addClass('player-medium');
      }
      if (this.elementWidth < 480) {
        this.player.addClass('player-small');
      }
      if (this.elementWidth < 360) {
        this.player.addClass('player-tiny');
      }
    },
    updateContentState() {
      const currentLocation = this.player.currentTime();
      let contentState;
      if (this.extraFields) {
        contentState = {
          ...this.extraFields.contentState,
          savedLocation: currentLocation || this.savedLocation,
        };
      } else {
        contentState = { savedLocation: currentLocation || this.savedLocation };
      }
      this.$emit('updateContentState', contentState);
    },
  },
};

